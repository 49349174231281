import React, { useState, useEffect, Fragment } from 'react';
import logo from '../assets/images/yovu-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import Spinner from '../components/Widgets/Spinner';
import axios from "axios";

// Import Firebase modules
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth';

import '../assets/scss/app-styles/signin.scss';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUcWBVk1HnFCSU_KnOUVOywjrave7z5rI",
  authDomain: "ssosaevolgoacrobits.firebaseapp.com",
  projectId: "ssosaevolgoacrobits",
  storageBucket: "ssosaevolgoacrobits.appspot.com",
  messagingSenderId: "1044086597941",
  appId: "1:1044086597941:web:30a3863391ed02c4a8447c",
  measurementId: "G-QZLE2240R3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Initialize Authentication
const googleProvider = new GoogleAuthProvider();  // Google Auth Provider
const microsoftProvider = new OAuthProvider('microsoft.com');  // Microsoft Auth Provider

const AppSignin = ({ history }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState("");
    const [passcode, setPasscode] = useState("");
    const [mfa, setMfa] = useState({});
    const [subscribers, setSubscribers] = useState([]);
    const [ssoType, setSsoType] = useState('');
    const [ssoToken, setSsoToken] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const myCloudId = url.searchParams.get("cloud_id");
        setCloudId(myCloudId.replaceAll("*", ""));

        // Handle the redirect result after Google or Microsoft SSO
        const handleRedirect = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result) {
                    const token = await result.user.getIdToken();
                    const providerId = result.providerId === 'google.com' ? 'google' : 'microsoft';
                    ssoSignin(token, providerId, '');
                }
            } catch (err) {
                toast.error("Sign-In Failed");
                console.log(err);
            }
        };
        handleRedirect();
    }, []);

    const appLogin = () => {
        setLoading(true);
        const creds = {
            "cloud_id": cloudId,
            "username": username,
            "password": password
        };
        axios.post(`${process.env.REACT_APP_API_URL}appSignin`, creds)
            .then(response => {
                setLoading(false);
                if (response.data.mfa === "mfa_required") {
                    setMfa(response.data);
                    return
                }
                let code = "csc:" + username + ":V2=" + btoa(password) + "@" + cloudId;
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*";
                }
                window.location.href = code;
                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);
            });
    };

    const googleLogin = () => {
        signInWithRedirect(auth, googleProvider);
    };

    const microsoftLogin = () => {
        signInWithRedirect(auth, microsoftProvider);
    };

    const ssoSignin = async (token, type, subscriberLogin) => {
        setLoading(true);
        const creds = {
            "cloud_id": cloudId,
            "type": type,
            "idToken": token
        };
        if (subscriberLogin) {
            creds.subscriber_login = subscriberLogin;
        }
        axios.post(`${process.env.REACT_APP_API_URL}ssoSignin`, creds)
            .then(response => {
                setLoading(false);
                if (response.data.subscribers && response.data.subscribers.length > 0) {
                    setSubscribers(response.data.subscribers);
                    setSsoType(type);
                    setSsoToken(token);
                    return;
                }
                if (response.data.mfa === "mfa_required") {
                    setUsername(response.data.username);
                    setPassword(type);
                    setMfa(response.data);
                    return
                }
                let code = "csc:" + response.data.username + ":V2=" + btoa(type) + "@" + cloudId;
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*";
                }
                window.location.href = code;
                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);
            });
    };

    const secondFactorAppLogin = () => {
        setLoading(true);
        const payLoad = {
            "cloud_id": cloudId,
            "username": username,
            "password": password,
            "passcode": passcode,
            "mfa_type": mfa.mfa_type,
            "mfa_vendor": mfa.mfa_vendor,
            "access_token": mfa.access_token,
        };
        axios.post(`${process.env.REACT_APP_API_URL}secondFactorAppSignin`, payLoad)
            .then(response => {
                setLoading(false);
                let code = "csc:" + username + ":V2=" + btoa(password) + "@" + cloudId;
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*";
                }
                window.location.href = code;
                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);
            });
    };

    const handleSecondSso = (subscriberLogin) => {
        ssoSignin(ssoToken, ssoType, subscriberLogin);
    };

    const goBack = () => {
        setMfa({});
        setSubscribers([]);
    };

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                        <div className="text-center"><img width="300" src={logo} alt="" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center">
                                    {loading && (
                                        <Spinner />
                                    )}
                                    <h4>Sign In</h4>
                                    <h6>
                                        {
                                            subscribers.length > 0 ?
                                                "Choose a Subscriber to Sign In As"
                                                :
                                                Object.keys(mfa).length === 0 ?
                                                    "Enter your Username and Password"
                                                    :
                                                    "Enter Passcode From Google Authenticator"
                                        }
                                    </h6>
                                </div>

                                {
                                    subscribers.length > 0 ?

                                        <div>
                                            <div className="subscriber-list">
                                                {
                                                    subscribers.map((subscriber, index) => {
                                                        return (
                                                            <div key={index} className="subscriber" onClick={e => handleSecondSso(subscriber.subscriber_login)} >
                                                                <span>{subscriber.subscriber_login}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <p className="go-back" onClick={goBack}>Go Back</p>
                                        </div>
                                        :
                                        Object.keys(mfa).length === 0 ?

                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Username</label>
                                                    <input className="form-control signin-input" type="text" name="username"
                                                        value={username}
                                                        onChange={e => setUsername(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Password</label>
                                                    <input className="form-control signin-input" type="password" name="password"
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block signin-button"
                                                        type="button" onClick={() => appLogin()} >Sign In</button>
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0 google-signin">
                                                    <button className="btn text-center btn-block" type="button"
                                                        onClick={googleLogin} >
                                                        <span> Sign in with Google</span>
                                                    </button>
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0 microsoft-signin">
                                                    <button className="btn text-center btn-block" type="button"
                                                        onClick={microsoftLogin} >
                                                        <span> Sign in with Microsoft Office365</span>
                                                    </button>
                                                </div>

                                            </form>

                                            :
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Passcode</label>
                                                    <input className="form-control signin-input" type="text" name="passcode"
                                                        value={passcode}
                                                        onChange={e => setPasscode(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block signin-button"
                                                        type="button" onClick={() => secondFactorAppLogin()} >Authenticate</button>
                                                </div>
                                                <p className="go-back" onClick={goBack}>Go Back</p>
                                            </form>
                                }
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(AppSignin);
