import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

    getSubscribers = (evt) => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }

        axios.post(`${process.env.REACT_APP_API_URL}getCloudIds`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.id}>{row.cloud_id}</option>)
            ));
            this.subscribersItems = items;
            this.setState({subscribersItems: items})
        }).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }


	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        if(JSON.parse(localStorage.getItem('creds')).cloud_id === "YOVU"){
            this.getSubscribers()
        }
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: localStorage.getItem('user_id')
			};
			axios.post(`${process.env.REACT_APP_API_URL}netsapienDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.api_url || !this.state.data.client_secret || !this.state.data.client_id || !this.state.data.superuser || !this.state.data.superpass) {
            toast.error('Fields with * are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id;
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateNetsapien`, payLoad, this.headers)
		.then(response => {
            let creds = JSON.parse(localStorage.getItem('creds'));
            creds["superuser"] = this.state.data.superuser;
            localStorage.setItem("creds",JSON.stringify(creds));
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if(err.response){
                toast.error(err.response.data);
            }
            else{
                console.log(err)
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/netsapiens',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Netsapiens" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Netsapien" : "Add Netsapien"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        API URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="api_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.api_url}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Client Secret
                                    </label> <span> * </span>
                                    <input type="password" className="form-control" name="client_secret"
                                        onChange={this.inputChangeHandler} value={this.state.data.client_secret}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Client Id
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="client_id"
                                    onChange={this.inputChangeHandler} value={this.state.data.client_id}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Delivery Web Hook
                                    </label>
                                    <input type="tet" className="form-control" name="delivery_webhook"
                                        onChange={this.inputChangeHandler} value={this.state.data.delivery_webhook}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Super User
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="superuser"
                                        onChange={this.inputChangeHandler} value={this.state.data.superuser}/>
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label className="float-left">
                                        Super User Password
                                    </label> <span> * </span>
                                    <input type="password" className="form-control" name="superpass"
                                        onChange={this.inputChangeHandler} value={this.state.data.superpass}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                {
                                    this.state.subscribersItems? 
                                        <div className="col-md-6">
                                            <label> Subscriber  </label>
                                            <select  className="form-control"
                                                onChange={this.selectChangeDataHandler} name="subscriber_id" 
                                                value={this.state.data.subscriber_id}>
                                                {this.state.subscribersItems}
                                            </select>
                                        </div>
                                    : null
                                }
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
