import React, { useState, useEffect, Fragment } from 'react';
import logo from '../assets/images/yovu-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { Login, LOGIN, Username, Password, CloudId } from '../constant';

const Signin = ({ history }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState("");
    const [attr, setAttr] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("custom-spinner-hide");

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );


    const resetPassword = () => {
        if(!username || !cloudId){
            toast.error("Username and Cloud Id is mandatory to reset Password")
            return;
        }
        setLoading("custom-spinner");
        const payLoad = {
            username: username,
            cloud_id: cloudId
        }
        axios.post(`${process.env.REACT_APP_API_URL}forgetPassword`, payLoad)
        .then(response=>{
            setLoading("custom-spinner-hide");
            toast.info("Please Follow the link emailed to reset the password!");
        })
        .catch(err=>{
            setLoading("custom-spinner-hide");
            toast.error(err.response.data);
        });
    }


    const loginAuth = async () => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL
        const creds = {
            "cloud_id" : cloudId,
            "user" : username,
            "password" : password
        };
        setAttr('disabled');
        setLoading("custom-spinner");
        console.log('REACT_APP_API_URL',process.env.REACT_APP_API_URL)
        axios.post(`${process.env.REACT_APP_API_URL}authLogin`,creds)
        .then(response =>{ 
            
            const obj = {
                'username':username,
                'cloud_id':cloudId,
                'name': response.data.name,
                'id': response.data.id,
                'user_type': response.data.user_type,
                'superuser': response.data.superuser
            };
            if(response.status === 200){
                localStorage.setItem('creds', JSON.stringify(obj));
                localStorage.setItem('token', response.data.Token);
                localStorage.setItem('profileURL', response.data.url);
    
                window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
                
            }
            setLoading("custom-spinner-hide");
            
        })
        .catch(err=>{
        
            console.log(err);
            setAttr('');
            setError('Invalid Credentials');
            setLoading("custom-spinner-hide");
        
        });
    
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                        <div className="text-center"><img width="300" src={logo} alt="" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center">
                                <div className={"loader-box " + loading} >
                                    <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    </div>
                                </div>
                                    <h4>{LOGIN}</h4>
                                    <h6>{"Enter your Username and Password"} </h6>
                                </div>
                                <form className="theme-form"> 
                                    <div className="form-group">
                                        <label className="col-form-label pt-0">{CloudId}</label>
                                        <input className="form-control" type="text" name="cloudId"
                                            value={cloudId}
                                            onChange={e => setCloudId(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label pt-0">{Username}</label>
                                        <input className="form-control" type="text" name="username"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">{Password}</label>
                                        <input className="form-control" type="password" name="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)} />
                                    </div>
                                    <div className="text-center">
                                        
                                        <h6 style={{color: "red"}} >{error} </h6>
                                    </div>
                                    <div className="form-group form-row mt-3 mb-0">
                                        <button className="btn btn-primary btn-block" disabled={attr} type="button" onClick={() => loginAuth()} >{Login}</button>
                                    </div>
                                    
                                </form>

                                <p className="mb-2 text-muted forgot-p">Forgot Password?
                                        <a style={{fontWeight: "800", cursor: "pointer", color: "black"}} 
                                            onClick={resetPassword}> Reset</a>
                                    </p>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Signin);